var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{staticClass:"px-4"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center"},[_c('div',{staticClass:"flex items-center"},[_c('div',[_c('vs-avatar',{staticClass:"sender__avatar--single flex-shrink-0 mr-3 border-2 border-solid border-white",attrs:{"size":"65px"}})],1),_c('div',{staticClass:"flex flex-col"},[(
                            _vm.email.type === 'received' &&
                            _vm.email.user &&
                            _vm.email.user.details
                        )?_c('h5',{staticClass:"mb-1 link",on:{"click":function($event){return _vm.$router.push({
                                name: 'user',
                                params: { id: _vm.email.user.details.user_id },
                            })}}},[_vm._v(" "+_vm._s(_vm.sentBy(_vm.email)[0])+" ")]):_c('h5',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.sentBy(_vm.email)[0]))]),_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.sentBy(_vm.email)[1]))]),_c('vs-dropdown',{staticClass:"inline-flex cursor-pointer",attrs:{"vs-custom-content":"","vs-trigger-click":""}},[_c('feather-icon',{staticClass:"w-3 h-3",attrs:{"icon":"ChevronDownIcon"}}),_c('vs-dropdown-menu',{staticStyle:{"z-index":"40001"}},[_c('div',{staticClass:"p-2"},[_c('p',{staticClass:"text-sm mb-1"},[_vm._v(" From: "),_c('span',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.sentBy(_vm.email)[1])+" ")])]),_c('p',{staticClass:"text-sm mb-1"},[_vm._v(" To: "),_c('span',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.sentTo(_vm.email))+" ")])]),_c('p',{staticClass:"text-sm mb-1"},[_vm._v(" Date: "),_c('span',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.formatDateAndTime( _vm.email.date_added ))+" ")])])])])],1)],1)])]),_c('div',{staticClass:"flex flex-col justify-end"},[_c('span',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatDate(_vm.email.date_added)))]),_c('div',{staticClass:"flex justify-end mt-2"},[_c('span',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatTime(_vm.email.date_added)))])])])])]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"mail__content break-words mt-8 mb-4",domProps:{"innerHTML":_vm._s(_vm.email.body_html || _vm.email.body_plain)}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }