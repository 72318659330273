var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"email__email-sidebar h-full"},[_c('div',{staticClass:"m-6 clearfix"},[_c('vs-button',{staticClass:"bg-primary-gradient w-full",attrs:{"icon-pack":"feather","icon":"icon-edit"},on:{"click":function($event){_vm.show.emailComposeModal = true}}},[_vm._v("Compose")])],1),_c('EmailComposeModal',{attrs:{"user":this.user,"show":_vm.show.emailComposeModal},on:{"show":function($event){_vm.show.emailComposeModal = $event},"refresh":function($event){return _vm.$emit('refresh')}}}),_c('VuePerfectScrollbar',{key:_vm.$vs.rtl,staticClass:"email-filter-scroll-area",attrs:{"settings":_vm.settings}},[_c('div',{staticClass:"px-6 pb-2 flex flex-col"},[_c('span',{staticClass:"flex justify-between items-center cursor-pointer",class:{ 'text-primary': _vm.filter == 'inbox' },on:{"click":function($event){return _vm.$emit('inbox')}}},[_c('div',{staticClass:"flex items-center mb-2"},[_c('feather-icon',{attrs:{"icon":"MailIcon","svgClasses":[
                            { 'text-primary stroke-current': true },
                            'h-6 w-6',
                        ]}}),_c('span',{staticClass:"text-lg ml-3"},[_vm._v("Inbox")])],1),_c('vs-chip',{directives:[{name:"show",rawName:"v-show",value:(_vm.receivedUnreadCount > 0),expression:"receivedUnreadCount > 0"}],staticClass:"number",attrs:{"color":"secondary"}},[_vm._v(_vm._s(_vm.receivedUnreadCount))]),_c('vs-chip',{directives:[{name:"show",rawName:"v-show",value:(_vm.receivedUnreadCount < 1),expression:"receivedUnreadCount < 1"}],staticClass:"number",attrs:{"color":"grey"}},[_vm._v(_vm._s(_vm.receivedCount))])],1),_c('span',{staticClass:"flex justify-between items-center cursor-pointer",class:{ 'text-primary': _vm.filter == 'sent' },on:{"click":function($event){return _vm.$emit('sent')}}},[_c('div',{staticClass:"flex items-center mb-2"},[_c('feather-icon',{attrs:{"icon":"SendIcon","svgClasses":[
                            {
                                'text-primary stroke-current':
                                    true == 'sent',
                            },
                            'h-6 w-6',
                        ]}}),_c('span',{staticClass:"text-lg ml-3"},[_vm._v("Sent")])],1),(_vm.sentCount > 0)?_c('vs-chip',{staticClass:"number",attrs:{"color":"grey"}},[_vm._v(_vm._s(_vm.sentCount))]):_vm._e()],1)]),_c('vs-divider'),(_vm.user && !_vm.user.id)?_c('div',{staticClass:"email__labels px-6 py-4"},[_c('h5',{staticClass:"mb-8"},[_vm._v("Labels")]),_c('div',{staticClass:"email__lables-list"},[_c('span',{staticClass:"email__label flex items-center mb-4 cursor-pointer"},[_c('div',{staticClass:"ml-1 h-3 w-3 rounded-full mr-4",class:'border-2 border-solid border-success'}),_c('span',{staticClass:"text-lg",class:{ 'text-primary': _vm.filter == 'users' },on:{"click":function($event){return _vm.$emit('users')}}},[_vm._v("From Users")])])])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }