<template>
    <div
        class="mail__mail-item sm:px-4 px-2 py-6"
        :class="{ 'mail__opened-mail': mail.type === 'received' && !mail.read }"
    >
        <!-- MAIL ROW 1 : META -->
        <div class="flex w-full">
            <vs-avatar
                class="sender__avatar flex-shrink-0 mr-3 border-2 border-solid border-white"
                :text="sentBy(mail)"
                size="40px"
            ></vs-avatar>

            <div class="flex w-full justify-between items-start">
                <div class="mail__details">
                    <h5
                        v-if="mail.type === 'received' && mail.user"
                        class="mb-1"
                        :class="{
                            'font-semibold':
                                mail.type == 'received' && !mail.read,
                        }"
                    >
                        <span v-if="mail.user">{{
                            mail.user.details.full_name
                        }}</span>
                    </h5>

                    <h5
                        v-else-if="mail.type === 'received'"
                        class="mb-1"
                        :class="{
                            'font-semibold':
                                mail.type == 'received' && !mail.read,
                        }"
                    >
                        {{ mail.sender }}
                    </h5>

                    <h5
                        v-if="mail.type === 'sent' && mail.sent_by"
                        class="mb-1"
                    >
                        {{ mail.sent_by.details.full_name }}
                    </h5>
                    <h5
                        v-else-if="mail.type === 'sent' && !mail.sent_by"
                        class="mb-1"
                    >
                        Sender Unknown
                    </h5>

                    <span v-if="mail.subject">{{ mail.subject }}</span>
                    <span v-else>(no subject)</span>
                </div>

                <div class="mail-item__meta flex items-center">
                    <div class="email__labels hidden sm:flex items-center">
                        <div
                            v-if="mail.type == 'received' && !mail.read"
                            class="h-2 w-2 rounded-full mr-2"
                            :class="'bg-secondary'"
                        ></div>
                        <div
                            v-if="mail.type == 'received' && mail.user"
                            class="h-2 w-2 rounded-full mr-2"
                            :class="'bg-success'"
                        ></div>
                    </div>
                    <span>{{ formatDateAndTime(mail.date_added) }}</span>
                </div>
            </div>
        </div>
        <!-- /MAIL ROW 1 -->

        <!-- MAIL ROW 2 : MSG & ACTIONS -->
        <div class="flex w-full">
            <div class="flex items-center ml-1">
                <vs-checkbox
                    v-model="isSelectedMail"
                    @click.stop
                    class="vs-checkbox-small ml-0 mr-1"
                ></vs-checkbox>
            </div>
            <div class="mail__message truncate ml-3">
                <div v-html="mailBody(mail)"></div>
            </div>
        </div>
        <!-- /MAIL ROW 2 -->
    </div>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'

export default {
    props: {
        mail: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            required: true,
        },
    },
    mixins: [textFormat],
    data() {
        return {
            isSelectedMail: this.isSelected,
        }
    },
    watch: {
        isSelected(value) {
            this.isSelectedMail = value
        },
        isSelectedMail(val) {
            val
                ? this.$emit('addToSelected', this.mail.id)
                : this.$emit('removeSelected', this.mail.id)
        },
    },
    computed: {
        labelColor() {
            return (label) => {
                const tags = this.$store.state.email.mailTags
                return tags.find((tag) => {
                    return tag.value == label
                }).color
            }
        },
    },
    methods: {
        toggleIsStarred() {
            const payload = {
                mailId: this.mail.id,
                value: !this.mail.isStarred,
            }
            this.$store.dispatch('email/toggleIsMailStarred', payload)
        },
        sentBy(value) {
            if (value.type === 'sent' && value.sent_by && value.sent_by.details)
                return value.sent_by.details.full_name
            if (value.type === 'received' && value.user)
                return value.user.details.full_name
            return ''
        },
        mailBody(mail) {
            if (mail.type === 'sent') return mail.body
            if (mail.type === 'received') return mail.body_plain
        },
    },
}
</script>
