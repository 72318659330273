<template>
    <div>
        <vs-button
            v-if="user.email"
            type="flat"
            class="mb-6"
            icon-pack="feather"
            icon="icon-chevron-left"
            v-on:click="$router.push({ name: 'user', params: { id: user.id } })"
            >{{ this.user.email }}</vs-button
        >

        <div
            id="email-app"
            class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden"
        >
            <vs-sidebar
                class="items-no-padding"
                parent="#email-app"
                :click-not-close="clickNotClose"
                :hidden-background="clickNotClose"
                v-model="isEmailSidebarActive"
            >
                <email-sidebar
                    @closeSidebar="toggleEmailSidebar"
                    :mailFilter="mailFilter"
                    :user="user"
                    :filter="filter"
                    :sentData="emailsSent"
                    :receivedData="emailsReceived"
                    :usersData="emailsUsers"
                    :compose="compose"
                    @composed="compose = false"
                    @inbox=";(filter = 'inbox'), (pageNumber = 1)"
                    @sent=";(filter = 'sent'), (pageNumber = 1)"
                    @users=";(filter = 'users'), (pageNumber = 1)"
                    @refresh="emailsFetch"
                />
            </vs-sidebar>

            <div
                :class="{ 'sidebar-spacer': clickNotClose }"
                class="no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0"
            >
                <!-- SEARCH BAR -->
                <div class="flex border d-theme-dark-bg items-center">
                    <feather-icon
                        class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer"
                        icon="MenuIcon"
                        @click.stop="toggleEmailSidebar(true)"
                    />
                    <vs-input
                        icon-no-border
                        icon="icon-search"
                        size="large"
                        icon-pack="feather"
                        placeholder="Search Mail"
                        v-model="searchQuery"
                        class="vs-input-no-border vs-input-no-shdow-focus w-full"
                    />
                </div>

                <!-- EMAIL ACTION BAR -->
                <div
                    class="email__actions flex items-center flex-wrap justify-between p-4 border border-r-0 border-l-0 border-solid d-theme-border-grey-light"
                >
                    <div class="flex items-center">
                        <vs-checkbox
                            v-model="selectAllCheckBox"
                            icon-pack="feather"
                            :icon="selectAllIcon"
                            class="select-all-chexkbox ml-0"
                            >Select All</vs-checkbox
                        >
                    </div>
                    <div class="flex">
                        <feather-icon
                            v-if="mailFilter != 'trash'"
                            icon="TrashIcon"
                            class="cursor-pointer ml-5"
                            svg-classes="h-6 w-6"
                            @click="deleteMail"
                        />
                    </div>
                </div>

                <!-- EMAILS LIST -->
                <VuePerfectScrollbar
                    class="email-content-scroll-area"
                    :settings="settings"
                    ref="mailListPS"
                    :key="$vs.rtl"
                >
                    <transition-group
                        v-if="filteredEmails && filteredEmails.data"
                        name="list-enter-up"
                        class="email__mails"
                        tag="ul"
                        appear
                    >
                        <li
                            class="cursor-pointer email__mail-item"
                            v-for="(email, index) in filteredEmails.data"
                            :key="String(filter) + String(email.id)"
                            @click="updateOpenMail(email)"
                            :style="{ transitionDelay: index * 0.1 + 's' }"
                        >
                            <mail-item
                                :mail="email"
                                :isSelected="isMailSelected(email.id)"
                                @addToSelected="addToSelectedMails"
                                @removeSelected="removeSelectedMail"
                            />
                        </li>
                    </transition-group>
                </VuePerfectScrollbar>
            </div>

            <!-- EMAIL VIEW SIDEBAR -->
            <email-view
                :emailData="selectedEmail"
                :isSidebarActive="isSidebarActive"
                :mailFilter="filter"
                @markUnread="updateSingleMarkUnread"
                @removeMail="removeOpenMail"
                @previousMail="previousMail"
                @nextMail="nextMail"
                @moveTo="moveCurrentTo"
                @closeSidebar="closeMailViewSidebar"
                @markUnreadOffline="
                    selectedEmail.type === 'received' ||
                    selectedEmail.type === 'users'
                        ? (selectedEmail.read = true)
                        : null
                "
                @reply="compose = true"
            >
            </email-view>
        </div>
        <vs-pagination
            v-if="filteredEmails"
            class="mt-3"
            :total="filteredEmails.number_of_pages"
            v-model="pageNumber"
        ></vs-pagination>
    </div>
</template>

<script>
import { http } from '@/services'
import EmailSidebar from './EmailSideBar.vue'
import MailItem from './MailItem.vue'
import EmailView from './EmailView.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    created() {
        if (this.$route.params.userAddress)
            this.user.email = this.$route.params.userAddress
        else this.user.email = null
        if (this.$route.params.userId) this.user.id = this.$route.params.userId
        else this.user.id = null
        if (this.$route.params.userId) this.emailsFetch()
    },
    beforeRouteUpdate(to, from, next) {
        next()
        if (this.$route.params.userAddress)
            this.user.email = this.$route.params.userAddress
        else this.user.email = null
        if (this.$route.params.userId) this.user.id = this.$route.params.userId
        else this.user.id = null
        if (this.$route.params.userId) this.emailsFetch()
    },
    data() {
        return {
            emails: {
                sent: null,
                received: null,
                users: null,
            },
            pageNumber: 1,
            compose: false,
            filter: 'inbox',
            selectedEmail: null,
            selectedEmails: [],
            selectedMails: [],
            isSidebarActive: false,
            showThread: false,
            clickNotClose: true,
            isEmailSidebarActive: true,
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            user: {
                email: null,
                id: null,
            },
        }
    },
    watch: {
        filter(value) {
            if (value === 'users') this.emailsUsersFetch()
            if (value === 'inbox') this.emailsReceivedFetch()
            if (value === 'sent') this.emailsSentFetch()
        },
        pageNumber(value) {
            this.pageNumber = value
            if (this.filter === 'users') this.emailsUsersFetch()
            if (this.filter === 'inbox') this.emailsReceivedFetch()
            if (this.filter === 'sent') this.emailsSentFetch()
        },
        isSidebarActive(value) {
            if (!value) this.showThread = false
        },
        mailFilter() {
            this.selectedMails = []
            this.$refs.mailListPS.$el.scrollTop = 0
            this.toggleEmailSidebar()
        },
        windowWidth: {
            immediate: true,
            handler(value) {
                this.setSidebarWidth()
            },
        },
    },
    computed: {
        filteredEmails() {
            if (this.filter === 'inbox' && this.emails.received)
                return this.emails.received
            if (this.filter === 'sent' && this.emails.sent)
                return this.emails.sent
            if (this.filter === 'users' && this.emails.users)
                return this.emails.users
            return this.emails.recieved
        },
        emailsReceived() {
            if (this.emails.received && this.emails.received.data)
                return this.emails.received.data
            return []
        },
        emailsSent() {
            if (this.emails.sent && this.emails.sent.data)
                return this.emails.sent.data
            return []
        },
        emailsUsers() {
            if (this.emails.users && this.emails.users.data)
                return this.emails.users.data
            return []
        },
        mailFilter() {
            return 'inbox'
        },
        searchQuery: {
            get() {
                return ''
            },
            set(val) {
                // this.$store.dispatch('email/setEmailSearchQuery', val)
            },
        },
        selectAllCheckBox: {
            get() {
                return 0
            },
            set(value) {
                value
                    ? (this.selectedEmails = this.filteredEmails)
                    : (this.selectedEmails = [])
            },
        },
        selectAllIcon() {
            return 'icon-check'
        },
        isMailSelected() {
            return (mailId) =>
                this.selectedEmails.findIndex((mail) => mail.id) == -1
                    ? false
                    : true
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
    },
    mounted() {
        this.emailsFetch()
    },
    methods: {
        emailsFetch() {
            this.emailsReceivedFetch()
        },
        emailsReceivedFetch() {
            http.get('emails_inbox', {
                params: { user_id: this.user.id, page_number: this.pageNumber },
            })
                .then((response) => {
                    this.emails.received = response.data
                })
                .catch((error) => {
                    console.log(error.response.data)
                })
        },
        emailsSentFetch() {
            http.get('emails_sent', {
                params: { user_id: this.user.id, page_number: this.pageNumber },
            })
                .then((response) => {
                    this.emails.sent = response.data
                })
                .catch((error) => {
                    console.log(error.response.data)
                })
        },
        emailsUsersFetch() {
            http.get('emails_users', {
                params: { page_number: this.pageNumber },
            })
                .then((response) => {
                    this.emails.users = response.data
                })
                .catch((error) => {
                    console.log(error.response.data)
                })
        },
        updateOpenMail(email) {
            this.selectedEmail = email
            this.isSidebarActive = true
        },
        addToSelectedMails(mailId) {
            if (this.selectedMails.indexOf(mailId) === -1)
                this.selectedMails.push(mailId)
        },
        removeSelectedMail(mailId) {
            const mailIndex = this.selectedMails.indexOf(mailId)
            if (mailIndex !== -1) this.selectedMails.splice(mailIndex, 1)
        },
        moveTo(to) {
            const payload = { emailIds: this.selectedMails, to: to }
            if (to === 'trash') {
                console.log('trash')
            }
            this.selectedMails = []
        },
        moveCurrentTo(to) {
            this.selectedMails = [this.selectedMail]
            this.moveTo(to)
        },
        updateMarkUnread() {
            const payload = { emailIds: this.selectedMails, unreadFlag: true }
            this.$store.dispatch('email/setUnread', payload)
            this.selectedMails = []
        },
        removeOpenMail() {
            this.selectedEmails = [
                { id: this.selectedEmail.id, type: this.selectedEmail.type },
            ]
            this.deleteMail()
            this.isSidebarActive = false
        },
        deleteMail() {
            if (this.selectedEmails) {
                http.post('emails_trash', this.selectedEmails)
                    .then((response) => {
                        // this.selectedEmails = []
                        this.isSidebarActive = false
                        this.emailsFetch()
                        this.$vs.notify({
                            text: 'Email deleted',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error.response.data)
                    })
            }
        },
        toggleIsStarred() {
            const payload = {
                mailId: this.currentMail.id,
                value: !this.currentMail.isStarred,
            }
            this.$store.dispatch('email/toggleIsMailStarred', payload)
        },
        nextMail() {
            if (this.selectedEmail) {
                var id = this.selectedEmail.id
                var index = this.filteredEmails.findIndex(
                    (mail) => mail.id === id
                )
                if (index < this.filteredEmails.length - 1)
                    this.selectedEmail = this.filteredEmails[index + 1]
            }
        },
        previousMail() {
            if (this.selectedEmail) {
                var id = this.selectedEmail.id
                var index = this.filteredEmails.findIndex(
                    (mail) => mail.id === id
                )
                if (index > 0)
                    this.selectedEmail = this.filteredEmails[index - 1]
            }
        },
        updateSingleMarkUnread() {
            if (this.selectedEmail && this.selectedEmail.type === 'received') {
                var url = 'user_email_detail_received'
                var data = {
                    id: this.selectedEmail.id,
                    read: !this.selectedEmail.read,
                }
                http.patch(url, data)
                    .then((response) => {
                        this.emailsFetch()
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                this.isSidebarActive = false
            }
        },
        updateLabels(label) {
            this.selectedMails = []
        },
        closeMailViewSidebar() {
            this.isSidebarActive = false
        },
        setSidebarWidth() {
            if (this.windowWidth < 992) {
                this.isEmailSidebarActive = this.clickNotClose = false
            } else {
                this.isEmailSidebarActive = this.clickNotClose = true
            }
        },
        toggleEmailSidebar(value = false) {
            if (!value) {
                this.closeMailViewSidebar()
                if (this.clickNotClose) {
                    return
                }
            }
            this.isEmailSidebarActive = value
        },
    },
    components: {
        MailItem,
        EmailSidebar,
        EmailView,
        VuePerfectScrollbar,
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/apps/email.scss';
</style>
