var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mail__mail-item sm:px-4 px-2 py-6",class:{ 'mail__opened-mail': _vm.mail.type === 'received' && !_vm.mail.read }},[_c('div',{staticClass:"flex w-full"},[_c('vs-avatar',{staticClass:"sender__avatar flex-shrink-0 mr-3 border-2 border-solid border-white",attrs:{"text":_vm.sentBy(_vm.mail),"size":"40px"}}),_c('div',{staticClass:"flex w-full justify-between items-start"},[_c('div',{staticClass:"mail__details"},[(_vm.mail.type === 'received' && _vm.mail.user)?_c('h5',{staticClass:"mb-1",class:{
                        'font-semibold':
                            _vm.mail.type == 'received' && !_vm.mail.read,
                    }},[(_vm.mail.user)?_c('span',[_vm._v(_vm._s(_vm.mail.user.details.full_name))]):_vm._e()]):(_vm.mail.type === 'received')?_c('h5',{staticClass:"mb-1",class:{
                        'font-semibold':
                            _vm.mail.type == 'received' && !_vm.mail.read,
                    }},[_vm._v(" "+_vm._s(_vm.mail.sender)+" ")]):_vm._e(),(_vm.mail.type === 'sent' && _vm.mail.sent_by)?_c('h5',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.mail.sent_by.details.full_name)+" ")]):(_vm.mail.type === 'sent' && !_vm.mail.sent_by)?_c('h5',{staticClass:"mb-1"},[_vm._v(" Sender Unknown ")]):_vm._e(),(_vm.mail.subject)?_c('span',[_vm._v(_vm._s(_vm.mail.subject))]):_c('span',[_vm._v("(no subject)")])]),_c('div',{staticClass:"mail-item__meta flex items-center"},[_c('div',{staticClass:"email__labels hidden sm:flex items-center"},[(_vm.mail.type == 'received' && !_vm.mail.read)?_c('div',{staticClass:"h-2 w-2 rounded-full mr-2",class:'bg-secondary'}):_vm._e(),(_vm.mail.type == 'received' && _vm.mail.user)?_c('div',{staticClass:"h-2 w-2 rounded-full mr-2",class:'bg-success'}):_vm._e()]),_c('span',[_vm._v(_vm._s(_vm.formatDateAndTime(_vm.mail.date_added)))])])])],1),_c('div',{staticClass:"flex w-full"},[_c('div',{staticClass:"flex items-center ml-1"},[_c('vs-checkbox',{staticClass:"vs-checkbox-small ml-0 mr-1",on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.isSelectedMail),callback:function ($$v) {_vm.isSelectedMail=$$v},expression:"isSelectedMail"}})],1),_c('div',{staticClass:"mail__message truncate ml-3"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.mailBody(_vm.mail))}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }