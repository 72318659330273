<template>
    <vx-card class="px-4">
        <!-- MAIL HEAD -->
        <div class="vx-row">
            <div
                class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center"
            >
                <!-- Sender Details -->
                <div class="flex items-center">
                    <div>
                        <vs-avatar
                            class="sender__avatar--single flex-shrink-0 mr-3 border-2 border-solid border-white"
                            size="65px"
                        ></vs-avatar>
                    </div>

                    <div class="flex flex-col">
                        <h5 class="mb-1">{{ sentBy(email)[0] }}</h5>
                        <div class="flex items-center">
                            <span class="text-sm">{{ sentBy(email)[1] }}</span>
                            <vs-dropdown
                                vs-custom-content
                                vs-trigger-click
                                class="inline-flex cursor-pointer"
                            >
                                <feather-icon
                                    icon="ChevronDownIcon"
                                    class="w-3 h-3"
                                />
                                <vs-dropdown-menu style="z-index: 40001">
                                    <div class="p-2">
                                        <p class="text-sm mb-1">
                                            From:
                                            <span class="font-semibold">
                                                {{ sentBy(email)[1] }}
                                            </span>
                                        </p>
                                        <p class="text-sm mb-1">
                                            To:
                                            <span class="font-semibold">
                                                {{ sentTo(email) }}
                                            </span>
                                        </p>
                                        <p class="text-sm mb-1">
                                            Date:
                                            <span class="font-semibold">
                                                {{
                                                    formatDateAndTime(
                                                        email.date_added
                                                    )
                                                }}
                                            </span>
                                        </p>
                                        <!--
                    <p class="text-sm mb-1" v-if="props.mailContent.cc.length">cc: <span class="font-semibold"> {{ props.mailContent.cc | csv }} </span></p>
                    -->
                                    </div>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>
                    </div>
                </div>
                <!-- /Sender Details -->

                <!-- Mail Time & Date -->
                <div class="flex flex-col justify-end">
                    <span class="text-right">{{
                        formatDate(email.date_added)
                    }}</span>
                    <div class="flex justify-end mt-2">
                        <span class="text-right">{{
                            formatTime(email.date_added)
                        }}</span>
                    </div>
                </div>
            </div>
            <!-- /Mail Time & Date -->
        </div>

        <!-- MAIL CONTENT -->
        <div class="vx-row">
            <div class="vx-col w-full">
                <div
                    class="mail__content break-words mt-8 mb-4"
                    v-html="email.body"
                ></div>
            </div>
        </div>

        <!-- MAIL ATTACHMENTS -->
        <!--
    <div class="vx-row" v-if="props.mailContent.attachments.length">
      <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid mb-6 flex">
        <feather-icon icon="PaperclipIcon" class="mr-2" />
        <span class="block py-4">ATTACHMENTS</span>
      </div>
      <div class="flex">
        <div class="mail__attachment" v-for="(attachment, index) in props.mailContent.attachments" :key="index">
          <vs-chip color="primary" class="px-4 py-2 mr-3">{{ attachment }}</vs-chip>
        </div>
      </div>
    </div>
    -->
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'

export default {
    name: 'EmailSentCard',
    props: ['data'],
    mixins: [textFormat],
    computed: {
        email() {
            if (this.data) return this.data
            return null
        },
    },
    methods: {
        sentBy(value) {
            var name = ''
            var email = ''
            if (value.type === 'sent' && value.sent_by && value.sent_by.details)
                name = value.sent_by.details.full_name
            if (value.type === 'sent' && value.sent_by && value.sent_by.details)
                email = value.sent_by.email
            return [name, email]
        },
        sentTo(value) {
            if (value.to) return value.to
            if (value.user) return value.user.details.full_name
            return ''
        },
    },
}
</script>
