<template>
    <div class="email__email-sidebar h-full">
        <div class="m-6 clearfix">
            <vs-button
                class="bg-primary-gradient w-full"
                icon-pack="feather"
                icon="icon-edit"
                @click="show.emailComposeModal = true"
                >Compose</vs-button
            >
        </div>

        <EmailComposeModal
            :user="this.user"
            :show="show.emailComposeModal"
            @show="show.emailComposeModal = $event"
            @refresh="$emit('refresh')"
        ></EmailComposeModal>

        <VuePerfectScrollbar
            class="email-filter-scroll-area"
            :settings="settings"
            :key="$vs.rtl"
        >
            <div class="px-6 pb-2 flex flex-col">
                <!-- inbox -->
                <span
                    @click="$emit('inbox')"
                    class="flex justify-between items-center cursor-pointer"
                    :class="{ 'text-primary': filter == 'inbox' }"
                >
                    <div class="flex items-center mb-2">
                        <feather-icon
                            icon="MailIcon"
                            :svgClasses="[
                                { 'text-primary stroke-current': true },
                                'h-6 w-6',
                            ]"
                        ></feather-icon>
                        <span class="text-lg ml-3">Inbox</span>
                    </div>
                    <vs-chip
                        v-show="receivedUnreadCount > 0"
                        class="number"
                        color="secondary"
                        >{{ receivedUnreadCount }}</vs-chip
                    >
                    <vs-chip
                        v-show="receivedUnreadCount < 1"
                        class="number"
                        color="grey"
                        >{{ receivedCount }}</vs-chip
                    >
                </span>

                <!-- sent -->
                <span
                    @click="$emit('sent')"
                    class="flex justify-between items-center cursor-pointer"
                    :class="{ 'text-primary': filter == 'sent' }"
                >
                    <div class="flex items-center mb-2">
                        <feather-icon
                            icon="SendIcon"
                            :svgClasses="[
                                {
                                    'text-primary stroke-current':
                                        true == 'sent',
                                },
                                'h-6 w-6',
                            ]"
                        ></feather-icon>
                        <span class="text-lg ml-3">Sent</span>
                    </div>

                    <vs-chip class="number" color="grey" v-if="sentCount > 0">{{
                        sentCount
                    }}</vs-chip>
                </span>
            </div>

            <vs-divider></vs-divider>
            <div v-if="user && !user.id" class="email__labels px-6 py-4">
                <h5 class="mb-8">Labels</h5>
                <div class="email__lables-list">
                    <span
                        class="email__label flex items-center mb-4 cursor-pointer"
                    >
                        <div
                            class="ml-1 h-3 w-3 rounded-full mr-4"
                            :class="'border-2 border-solid border-success'"
                        ></div>
                        <span
                            @click="$emit('users')"
                            class="text-lg"
                            :class="{ 'text-primary': filter == 'users' }"
                            >From Users</span
                        >
                    </span>
                </div>
            </div>
        </VuePerfectScrollbar>
    </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import EmailComposeModal from './EmailComposeModal'

export default {
    props: ['user', 'filter', 'sentData', 'receivedData', 'compose'],
    components: {
        VuePerfectScrollbar,
        EmailComposeModal,
    },
    data() {
        return {
            show: {
                emailComposeModal: false,
            },
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
        }
    },
    watch: {
        compose: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.show.emailComposeModal = true
                    this.$emit('composed')
                }
            },
        },
    },
    computed: {
        baseUrl() {
            const path = this.$route.path
            return path.slice(0, path.lastIndexOf('/'))
        },
        emailMeta() {
            return ''
        },
        sentCount() {
            if (this.sentData) return this.sentData.length
            return 0
        },
        receivedCount() {
            if (this.receivedData) return this.receivedData.length
            return 0
        },
        receivedUnreadCount() {
            if (this.receivedData)
                return this.receivedData.filter((email) => !email.read).length
            return 0
        },
        receivedColour() {
            if (this.receivedUnreadCount > 0) return 'secondary'
            return 'grey'
        },
    },
    methods: {},
}
</script>
